<ng-container *transloco="let t; read: 'side-nav'">
    <div class="side-nav" [ngClass]="{'closed' : (navService.sideNavCollapsed$ | async), 'hidden': (navService.sideNavVisibility$ | async) === false, 'no-donate': (accountService.hasValidLicense$ | async) === true}"  *ngIf="accountService.currentUser$ | async as user">
      <app-side-nav-item icon="fa-home" [title]="t('home')" link="/libraries/">
        <ng-container actions>
          <app-card-actionables [actions]="homeActions" [labelBy]="t('home')" iconClass="fa-ellipsis-v" (actionHandler)="handleHomeActions()"></app-card-actionables>
        </ng-container>
      </app-side-nav-item>

      <ng-container *ngIf="navStreams$ | async as streams">
        <ng-container *ngIf="showAll">
          <app-side-nav-item icon="fa fa-chevron-left" [title]="t('back')" (click)="showLess()"></app-side-nav-item>
          <div class="mb-2 mt-3 ms-2 me-2" *ngIf="streams.length > 10 && (navService?.sideNavCollapsed$ | async) === false">
            <label for="filter" class="form-label visually-hidden">{{t('filter-label')}}</label>
            <div class="form-group">
              <input id="filter" autocomplete="off" class="form-control" [(ngModel)]="filterQuery" type="text" aria-describedby="reset-input">
              <button type="button" [attr.aria-label]="t('clear')" class="btn-close" id="reset-input" (click)="filterQuery = '';"></button>
            </div>
          </div>
        </ng-container>

        <ng-container *ngFor="let navStream of streams | filter: filterLibrary">
          <ng-container [ngSwitch]="navStream.streamType">
            <ng-container *ngSwitchCase="SideNavStreamType.Library">
              <app-side-nav-item [link]="'/library/' + navStream.libraryId + '/'"
                                 [icon]="getLibraryTypeIcon(navStream.library!.type)" [imageUrl]="getLibraryImage(navStream.library!)" [title]="navStream.library!.name" [comparisonMethod]="'startsWith'">
                <ng-container actions>
                  <app-card-actionables [actions]="actions" [labelBy]="navStream.name" iconClass="fa-ellipsis-v"
                                        (actionHandler)="performAction($event, navStream.library!)"></app-card-actionables>
                </ng-container>
              </app-side-nav-item>
            </ng-container>

            <ng-container *ngSwitchCase="SideNavStreamType.AllSeries">
              <app-side-nav-item icon="fa-regular fa-rectangle-list" [title]="t('all-series')" link="/all-series/"></app-side-nav-item>
            </ng-container>

            <ng-container *ngSwitchCase="SideNavStreamType.Bookmarks">
              <app-side-nav-item icon="fa-bookmark" [title]="t('bookmarks')" link="/bookmarks/"></app-side-nav-item>
            </ng-container>
            <ng-container *ngSwitchCase="SideNavStreamType.ReadingLists">
              <app-side-nav-item icon="fa-list-ol" [title]="t('reading-lists')" link="/lists/">
                <ng-container actions>
                  <app-card-actionables [actions]="readingListActions" [labelBy]="t('reading-lists')" iconClass="fa-ellipsis-v" (actionHandler)="importCbl()"></app-card-actionables>
                </ng-container>
              </app-side-nav-item>
            </ng-container>

            <ng-container *ngSwitchCase="SideNavStreamType.Collections">
              <app-side-nav-item icon="fa-list" [title]="t('collections')" link="/collections/"></app-side-nav-item>
            </ng-container>

            <ng-container *ngSwitchCase="SideNavStreamType.WantToRead">
              <app-side-nav-item icon="fa-star" [title]="t('want-to-read')" link="/want-to-read/"></app-side-nav-item>
            </ng-container>

            <ng-container *ngSwitchCase="SideNavStreamType.SmartFilter">
              <app-side-nav-item icon="fa-bars-staggered" [title]="navStream.name" link="/all-series" [queryParams]="navStream.smartFilterEncoded"></app-side-nav-item>
            </ng-container>

            <ng-container *ngSwitchCase="SideNavStreamType.ExternalSource">
              <app-side-nav-item icon="fa-server" [title]="navStream.name" [link]="navStream.externalSource.host + 'login?apiKey=' + navStream.externalSource.apiKey" [external]="true"></app-side-nav-item>
            </ng-container>


          </ng-container>
        </ng-container>

        <ng-container *ngIf="totalSize > 10 && !showAll">
          <app-side-nav-item icon="fa fa-chevron-right" [title]="t('more')" (click)="showMore()"></app-side-nav-item>
        </ng-container>
      </ng-container>
    </div>
    <div class="side-nav-overlay" (click)="toggleNavBar()" [ngClass]="{'closed' : (navService.sideNavCollapsed$ | async)}"></div>
    <div class="bottom" [ngClass]="{'closed' : (navService.sideNavCollapsed$ | async),
        'hidden': (navService.sideNavVisibility$ | async) === false || (accountService.hasValidLicense$ | async) === true}">
      <app-side-nav-item *ngIf="(accountService.hasValidLicense$ | async) === false" [ngClass]="'donate'" icon="fa-heart" [title]="t('donate')" link="https://opencollective.com/kavita" [external]="true"></app-side-nav-item>
    </div>
</ng-container>
